div.yt-warper{

	position: relative;
	padding-bottom: 56.25%;
	width: 100%;
	height: 0;

}

div.yt-warper iframe {

	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;


}
