.news {

    background: #f8f8f8;
    font-family: 'Comfortaa', sans-serif;
    padding: 60px 0;
    margin-bottom: 0;
    position: relative;
    z-index: 400;

    overflow: hidden;

    &__content {
        background: #fff;
        margin-left: 40px;

        @include media-breakpoint-down(sm){

          margin-left: 0;

        }

        &--info {
            width: 80%;
            margin-top: 150px;
            margin-left: 80px;
            margin-bottom: 115px;

            @include media-breakpoint-down(sm){

              margin: 40px;

            }

            & p {
                font-size: 16px;
                margin-bottom: 27px;
            }
        }
    }
}

.wrapper {
    overflow-x: hidden;
}

.left:after {
  animation: translateLeft 1s forwards;
}

.right:after {
  animation: translateRight 1s forwards;
}

@media screen and (max-width:767px) {
    .inspirations .inspirations__content {
        padding: 50px 30px;

        @include media-breakpoint-down(sm){

          padding: 25px 0;

        }

        @include media-breakpoint-down(xs){

          padding: 0;

        }

    }
}

.inspirations .inspirations__content header {

    margin-bottom: 60px;

    @include media-breakpoint-down(sm){

      margin-bottom: 30px;

    }


}

//KIFF
.inspirations{

  .inspirations__content{

    header{

      h2{

        font-size: 1.7rem;
        color: #fff;
        text-transform: uppercase;

      }

      h3{

        font-size: 4rem;
        color: #fff;

      }

    }

    .main{

      @include media-breakpoint-down(sm){

        margin-bottom: 20px;

      }

      .wrapper {

        @include media-breakpoint-down(sm){

          margin-left: 0;

        }

      }

      ul {

        position: relative;
        box-sizing: content-box;

        margin: 0;
        padding: 0;

        padding-bottom: 60px;

        transition: transform 1s ease;

        @include media-breakpoint-down(sm){

          margin-left: -40px;
          padding-bottom: 0;

        }

        li{

          transition: opacity 1s ease;
          position: absolute;

          margin: 0;
          padding: 0;

          margin-left: 40px;
          box-shadow: 0 11px 40px 0 rgba(0, 0, 0, .1);

          list-style: none;

          overflow: hidden;

          @include media-breakpoint-down(sm){

            box-shadow: initial;

          }

          &.opacity {

              opacity: 0;

          }

          a{

            background: #000;
            display: inline-block;
            position: relative;
            display: flex;

            &:hover{

              .js-replace-img {
                  opacity: 0.5;
                  transition: all .75s ease;
              }

              .more {
                  opacity: 1;
                  transition: all .75s ease;
              }

              .foot {
                  opacity: 0;
                  transform: translateY(100%);
                  transition: all .75s ease;
              }

            }

            .js-replace-img {

                height: 380px;
                width: 500px;
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center center;

                transition: all .75s ease;

                @include media-breakpoint-down(sm){

                  max-width: 380px;

                }

            }

            .more {

                opacity: 0;
                position: absolute;
                z-index: 99;
                width: 100%;
                color: white;
                height: 100%;

                .center {

                    text-align: center;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);

                    .circle {

                        background: white;
                        color: black;
                        width: 50px;
                        height: 50px;
                        border-radius: 100%;
                        position: relative;
                        left: 50%;
                        transform: translateX(-50%);
                        text-align: center;
                        margin-bottom: 10px;

                        &:after{

                          content: "+";
                          color: #000;
                          font-size: 2rem;

                          position: absolute;
                          top: 50%;
                          left: 50%;

                          transform: translate(-50%,-50%);

                        }

                    }

                }
            }

            .foot {
                background: white;

                width: 100%;

                position: absolute;
                bottom: 0;
                left: 0;
                padding: 20px;

                transition: all .75s ease;

                h3{

                  font-size: 1.8rem;
                  color: #000;

                }

                h4{

                  font-size: 1.8rem;
                  color: #ddd;

                }


            }

          }

        }

      }

      footer {

        display: flex;
        justify-content: flex-end;
        flex-wrap: nowrap;

        padding: 0;

        @include media-breakpoint-down(sm){

          flex-direction: column

        }

        a{

          font-size: 1.7rem;
          font-weight: 500;
          text-transform: uppercase;
          padding: 12px 20px;
          display: inline-block;
          height: 50px;
          color: #fff;
          border: 2px solid #fff;
          transition: all .75s ease;

          @include media-breakpoint-down(sm){

            margin-bottom: 30px;
            padding: 12px 15px;

          }

          &:hover {

              background-color: #334e5d;
              border-color: #334e5d;
              color: #fff

          }

        }

      }

    }

  }

}


.inspirations .inspirations__content footer .arrows {

  display: flex;

  @media screen and (max-width:767px) {

    justify-content: flex-end

  }

  li {

    display: flex;
    justify-content: center;
    align-items: center;

    &:first-child{

      .arrow{

        margin-left: 0;

      }

    }

    .arrow {

      position: relative;
      padding:15px 20px 15px 10px;
      border: 2px solid $brand-primary;
      margin-left: 30px;
      cursor: pointer;
      transition: all .75s ease;

      .cd-arrow {

          width: 15px;
          height: 15px;
          border-right: 2px solid $brand-primary;
          border-top: 2px solid $brand-primary;
          display: block;

          transition: all .75s ease;

          &.cd-arrow-right{

              transform: rotateZ(-135deg);

          }

          &.cd-arrow-left{

              transform: rotateZ(45deg);

              @media screen and (max-width:767px) {
                margin-left: 0
              }

          }

      }

      &:hover {

        background: $brand-primary;

        .cd-arrow {

          border-right: 2px solid #fff;
          border-top: 2px solid #fff;

        }

      }

    }

    &:nth-child(1) .arrow{

        padding:15px 10px 15px 20px;

    }

  }

}
