.colophon{

	background: #fff;
	margin-top: $margin-base*2;

	.navColophon{

		p{

			float:left;
			color: #222222;

			@include media-breakpoint-down(sm){

				float: none;
				text-align: center;

			}

		}

		.colophon_navigation{

			margin: 0;
			padding: 0;

			float:left;

			@include media-breakpoint-down(sm){

				float: none;
				text-align: center;

			}

			li{

				margin: 0 0 0 10px;
				padding: 0 0 0 10px;

				display: inline-table;
				list-style: none;
				border-left: 1px solid #222222;

				@include media-breakpoint-down(sm){

					display: block;
					border: none;

					margin-bottom: 10px;

				}

				a{

					color: #222222;
					font-size: 1.2rem;
					text-decoration: underline;

				}

			}

		}

	}

	.kiff-logo{

		background: url("../images/mdpi/kiff-logo.png") no-repeat center/contain;
		width: 40px;
		height: 40px;

		float:right;
		text-indent: -15000px;

		@include media-breakpoint-down(sm){

			display: block;
			float: none;

			margin: 20px auto;

		}

	}

}
