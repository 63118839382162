html{

  font-size: 62.5%;

}

body{

  background: #fff;
  font-family: $font-face-text;

  min-height: 1000px;

  &.overflow{

    overflow: hidden;

  }

  &.page{

    main{

      padding-top: 175px;

    }

  }

}

.js-replace-img {
     background-size: cover;
     background-position: center center;

    img {
        display: none;
    }
}

.no-padding {

    padding: 0;

}
.no-margin {

    margin: 0;

}

$multiplyer-list: (
    'base':1,
    'medium':2,
    'large':3,
    'wide':5
);

$directions-list: 'top' 'right' 'bottom' 'left';

@each $key,$value in $multiplyer-list {

    .padding-#{$key} {

        padding:$margin-base*$value;

    }
    .margin-#{$key} {

        margin:$margin-base*$value;

    }

    .margin-vertical-#{$key} {

        padding:$margin-base*$value 0;

    }
    .padding-vertical-#{$key} {

        margin:$margin-base*$value 0;

    }

    .margin-horizontal-#{$key} {

        padding:0 $margin-base*$value;

    }
    .padding-horizontal-#{$key} {

        margin:0 $margin-base*$value;

    }

    @each $current-direction in $directions-list {

        $i:index($directions-list,$current-direction);
        .margin-#{$current-direction}-#{$key} {

            margin-#{$current-direction}:$margin-base*$value;

        }

        .padding-#{$current-direction}-#{$key} {

            padding-#{$current-direction}:$margin-base*$value;

        }
    }
}
