.main-header {

  width: 100%;
  height: 175px;
  box-sizing: border-box;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;

  transition: all .4s $easeInOutQuart;

  &::after {

    background: #fff;

    content: "";
    width: 100%;
    height: 175px;

    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;

    transform: translate(0,0);
    transition: all .4s $easeInOutQuart;

  }

  nav{

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    justify-content: space-between;
    align-items: center;

    padding: 20px;
    height: 100%;

    a.brand-logo {

      display: block;
      width: 125px;
      height: 100%;

      text-indent: -15000px;

      position: relative;

      transition: all .4s $easeInOutQuart;

      z-index: 10010;

      &:after, &:before {

        content: "";
        display: block;
        width: 100%;
        height: 100%;

        background: url("../images/mdpi/logo-blue.png")no-repeat;
        background-position: left center;
        background-size: contain;

        position: absolute;
        top: 0;
        left: 0;

        transition: all .1s $easeInOutQuart;

      }

      &:after {

        background: url("../images/mdpi/logo-blue-icon.png")no-repeat;
        background-position: left center;
        background-size: contain;

        opacity: 0;


      }

    }

    .nav-wrapper {

      transition: all .4s $easeInOutQuart;

      >ul {

        padding: 0;
        margin: 0;

        height: 100%;

        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;

        justify-content: center;

        li {

          padding: 0;
          margin: 0 20px 0 0;

          float: left;

          list-style: none;

          &:last-child{

            margin: 0;

          }

          a{

            display: inline-block;

            background: linear-gradient(45deg, $brand-secondary 0%, $brand-secondary 55%, $brand-primary 90%) no-repeat;
            background-position: 100% 0;
            background-size: 200% 200%;

            border: 1px solid transparent;

            padding: 0 25px;
            height: 40px;
            line-height: 40px;

            text-decoration: none;
            font-size:1.2rem;
            text-transform: uppercase;
            letter-spacing: 0.10rem;
            color: #fff;

            transition:  all .25s ease;

            i{

              margin-right: 10px;

            }

            &:hover {

              background: linear-gradient(45deg, $brand-secondary 0%, $brand-secondary 55%, $brand-primary 90%) no-repeat;
              background-size: 200% 200%;

              background-position: 0 0;
              text-decoration: none;
              color: #000;

              transition:  all .75s ease;

            }

          }

        }

      }

      .main-nav-wrapper{

        opacity: 0;
        visibility: hidden;
        display: none;

        background: $gradientopcity;
        display: block;
        width: 100vw;
        height: 100vh;
        box-sizing: border-box;

        float: none;
        position: fixed;
        top:0;
        left: 0;

        overflow: hidden;

        z-index: 10001;

        transition: all .4s ease-out;

        ul{

          position: absolute;
          top: 200px;
          right: 100px;

          text-align: right;

          li{

            list-style: none;

            float: none;
            opacity: 0;

            margin-bottom: 20px;

            transform: translateX(-50%);

            a{

              background: none !important;

              color: #fff !important;
              font-size: 3rem;

              @include media-breakpoint-down(sm){

                font-size: 1.8rem;

              }

              &:hover{



              }

            }

          }

        }

      }

      .btn-menu {

        background: transparent;
        position: relative;

        width: 4.8rem;
        height: 100%;
        border: none;

        margin-right: 10px;

        z-index: 10;

        span {

          &, &:after, &:before {

            content: '';
            display: block;
            width: 20px;
            height: 2px;

            background: $brand-primary;

            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);

            transition: all .4s $authenticMotion;

          }


          &:after {

            transform: translate(-50%,-300%);

          }

          &:before {

            transform: translate(-50%,200%);

          }

        }

        &.cross {

          z-index: 10002;

          span {

            background: transparent;

            &:after {

              transform: translate(-50%,-50%) rotate(45deg);
              background: #fff !important;

            }

            &:before {

              transform: translate(-50%,-50%) rotate(-45deg);
              background: #fff !important;

            }

          }

        }

      }

    }

  }

  @include media-breakpoint-down(sm){

    height: 100px;

    &:after{

      height: 100px;

    }

    nav{

      a.brand-logo {

        display: block;
        width: 125px;
        height: 100%;

        text-indent: -15000px;

        position: relative;

        transition: all .4s $easeInOutQuart;

        &:after, &:before {

          content: "";
          display: block;
          width: 100%;
          height: 100%;

          background: url("../images/mdpi/logo-blue-icon.png")no-repeat;
          background-position: left center;
          background-size: contain;

          position: absolute;
          top: 0;
          left: 0;


          transition: all .1s $easeInOutQuart;

        }

        &:after {

          background: url("../images/mdpi/logo-blue-icon.png")no-repeat;
          background-position: left center;
          background-size: contain;

          opacity: 0;


        }

      }

      .nav-wrapper{

        > ul{

          li{

            margin-right: 10px;

            a{

              padding: 0 15px;

              span{

                display: none;

              }

              i{

                margin-right: 0;

              }

            }

          }

        }

      }

    }

  }

  // SCROLL
  &.min{

    height: 75px;

    &::after{

      background: #fff !important;
      height: 75px;

      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1), 0 5px 5px 0 rgba(0, 0, 0, 0.1);

    }

    nav{

      padding: 10px 20px;

      @include media-breakpoint-down(sm){

        padding: 10px 0 10px 20px;

      }

      .brand-logo {

        transform: translate(0px, 0px) !important;
        transition: all .4s $easeInOutQuart;

        &:before{

          opacity: 0;

        }

        &:after{

          opacity: 1;

        }

      }

      .nav-wrapper {

        >ul li{

          a{

            display: inline-block;

            background: linear-gradient(45deg, $brand-secondary 0%, $brand-secondary 55%, $brand-primary 90%) no-repeat !important;
            background-position: 100% 0 !important;
            background-size: 200% 200% !important;
            color: #fff;

            border: 1px solid transparent;

            padding: 0 25px;
            height: 40px;
            line-height: 40px;

            text-decoration: none;
            font-size:1.2rem;
            text-transform: uppercase;
            letter-spacing: 0.10rem;
            color: #fff !important;

            transition:  all .25s ease;

            i{

              margin-right: 10px;
              color: #fff !important;

            }

            &:hover {

              background: linear-gradient(45deg, $brand-secondary 0%, $brand-secondary 55%, $brand-primary 90%) no-repeat !important;
              background-size: 200% 200% !important;
              background-position: 0 0 !important;
              text-decoration: none;
              color: #000;

              transition:  all .75s ease;

            }

              @include media-breakpoint-down(sm){

                padding: 0 15px;

                span{

                  display: none;

                }

                i{

                  margin-right: 0;

                }

              }

          }

        }

        .btn-menu {

          span{

            &, &:after, &:before{

              background: $brand-primary !important;

            }

          }

          &.cross {

            span {

              background: transparent !important;

              &:after, &:before{

                background: #fff !important;

              }

            }

          }

        }

      }

    }

  }

  &.open{

    nav{

      .nav-wrapper {

        >ul li{

          z-index: 19999;

          a{

            background: #fff !important;
            color: $brand-primary !important;

            i{

              color: $brand-secondary !important;

            }

            &:hover{

              background: $brand-primary !important;
              color: #fff !important;

            }

          }

        }

        .btn-menu {

          span{

            &, &:after, &:before{

              background: #fff;

            }

          }

          &.cross {

            &:after, &:before{

              background: #fff !important;

            }

            span {

              background: transparent !important;

            }

          }

        }

        .main-nav-wrapper{

          display: block !important;
          opacity: 1 !important;
          visibility: visible !important;

          ul{

            li{

              transform: translateX(0);
              opacity: 1;
              transition: all .4s ease-out;

              &:nth-child(1n){

                transition-delay: 0s;

              }

              &:nth-child(2n){

                transition-delay: .2s;

              }

              &:nth-child(3n){

                transition-delay: .4s;

              }

              &:nth-child(4n){

                transition-delay: .6s;

              }

              &:nth-child(5n){

                transition-delay: .8s;

              }

              &:nth-child(6n){

                transition-delay: 1s;

              }

              &:nth-child(7n){

                transition-delay: 1.2s;

              }

              &:nth-child(8n){

                transition-delay: 1.3s;

              }

            }

          }

        }

      }

    }

  }

}

.home{

  .main-header {

    &:after{

      background: transparent;

    }

    nav{

      .brand-logo{

        transform: translate(20px, 20px);

        &:before{

          background: url("../images/mdpi/logo.png")no-repeat;
          background-position: left center;
          background-size: contain;

          @include media-breakpoint-down(sm){

            background: url("../images/mdpi/logo-icon.png")no-repeat;
            background-position: left center;
            background-size: contain;

          }

        }

      }

      .nav-wrapper {

        ul li{

          a{

            background: #fff;
            color: $brand-primary;

            i{

              color: $brand-secondary;

            }

            &:hover{

              background: $brand-primary;
              color: #fff;

            }

          }

        }

        .btn-menu {

          span{

            &, &:after, &:before{

              background: #fff;

            }

          }

          &.cross {

            &:after, &:before{

              background: #fff !important;

            }

            span {

              background: transparent !important;

            }

          }

        }

      }

    }

  }

}
