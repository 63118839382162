.btn-theme{

  display: inline-block;

  background: transparent;
  border: 1px solid transparent;

  padding: 0 25px;
  height: 40px;
  line-height: 40px;

  text-decoration: none;
  font-size:1.2rem;
  text-transform: uppercase;
  letter-spacing: 0.10rem;

  transition:  all .75s ease;

  @include media-breakpoint-down(sm){

    margin-bottom: 10px;

  }

  &:hover{


  }

  &.primary{

    background: $brand-primary;
    color: #fff;

    &:hover{

      background: $brand-secondary;
      color: #fff;

      transition:  all .75s ease;

    }

  }

  &.secondary{

    background: $brand-secondary;
    color: #fff;

    &:hover{

      background: $brand-primary;
      color: #fff;

      transition:  all .75s ease;

    }

  }

  &.white{

    background: #fff;
    color: $brand-primary;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);

    transition:  all .75s ease;

    &:hover{

      background: $brand-primary;
      color: #fff;
      box-shadow: 0px 0px 5px 0px rgba(0,0,0,0);

      transition:  all .75s ease;

    }

  }

  &.gradient{

    background-image: linear-gradient(45deg, $brand-secondary 0%, $brand-secondary 55%, $brand-primary 90%);
    background-position: 100% 0;
    background-size: 200% 200%;
    color: #fff;

    transition:  all .75s ease;

    &:hover {

      background-position: 0 0;
      text-decoration: none;
      color: #000;

      transition:  all .75s ease;

    }

  }

  &.green{

    background: #008d6d;
    color: #fff;

    transition:  all .75s ease;

    &:hover{

      background: #fff;
      color: $brand-primary;
      box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);

      transition:  all .75s ease;

    }

    &-gradient{

      background: linear-gradient(45deg, #55e1bb 0%, #008d6d 90%);
      background-position: 0 0;
      background-size: 200% 200%;
      color: #fff;

      transition:  all .75s ease;

      &:hover {

        background-position: 100% 0 !important;
        color: #fff;

        transition:  all .75s ease;

      }

    }

  }

  &.orange{

    background: #c5b610;
    color: #fff;

    transition:  all .75s ease;

    &:hover{

      background: #fff;
      color: $brand-primary;
      box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);

      transition:  all .75s ease;

    }

    &-gradient{

      background: linear-gradient(45deg, #ebe064 0%, #c5b610 90%);
      background-position: 0 0;
      background-size: 200% 200%;
      color: #fff;

      transition:  all .75s ease;

      &:hover {

        background-position: 100% 0 !important;
        color: #fff;

        transition:  all .75s ease;

      }

    }

  }

  &.red{

    background: #8d0000;
    color: #fff;

    transition:  all .75s ease;

    &:hover{

      background: #fff;
      color: $brand-primary;
      box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);

      transition:  all .75s ease;

    }

    &-gradient{

      background: linear-gradient(45deg, #c95b5b 0%, #8d0000 90%);
      background-position: 0 0;
      background-size: 200% 200%;
      color: #fff;

      transition:  all .75s ease;

      &:hover {

        background-position: 100% 0 !important;
        color: #fff;

        transition:  all .75s ease;

      }

    }

  }

}
