.grid-home-page{

  .row{

    >div{

      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      article{

        position: relative;
        margin-bottom: 30px;

        &:after{

          content: "";
          display: block;

          padding-top: 100%;

        }

        &.no-after{

          &:after{

            display: none;

            @include media-breakpoint-down(sm){

              display: block;

            }

          }

        }

        div{

          height: 100%;
          position: relative;

          &.blue{

            background: $brand-primary;

            figcaption{

              color: #fff;

              h2{

                color: #fff;

              }

            }

          }

          &.blue-light{

            background: $brand-secondary;

            figcaption{

              color: #fff;

              h2{

                color: #fff;

              }

            }

          }

          &.blue-extra-light{

            background: $brand-light;

          }

          a{

            display: block;
            height: 100%;
            width: 100%;

            figcaption{

              position: absolute;
              top: 50%;
              width: 100%;

              text-align: center;
              color: #000;

              transform: scale(1) translateY(-50%);
              transition:  all .25s ease;

              i{

                font-size: 4rem;
                margin-bottom: 10px;

              }

              h2{

                font-size: 1.6rem;
                margin: 0;
                padding: 0 20px;

                color: #000;

              }

            }

            &:hover{

              figcaption{

                transform: scale(1.1) translateY(-50%);
                transition:  all .25s ease;

              }

            }

          }

        }

      }

    }

  }

}
