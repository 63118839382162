// BREADCUMBS
.breadcrumbs{

  margin-bottom: 20px;

  @include media-breakpoint-down(sm){

    display: none;

  }

  ul{

    margin: 0;
    padding: 0;

    li{

      position: relative;
      display: inline-table;

      margin: 0;
      padding: 0 0 0 20px;
      list-style: none;

      color: #bebcbc;
      text-transform: uppercase;

      &:first-child{

        padding: 0;

        &:before{

          display: none;

        }

      }

      &:before{

        content: ">";
        color: #bebcbc;

        font-size: 1.2rem;

        position: absolute;
        left: 5px;
        top: -2px;

      }

      a{

        color: #bebcbc;
        text-decoration: underline;

      }

    }

  }

}

// CONTENT TEXT
.text-full{

  &.background{

    background: #fff;
    padding: 40px;

    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.1), 0 24px 24px 0 rgba(0, 0, 0, 0.1);

    @include media-breakpoint-down(sm){

      padding: 20px;

    }

  }

  .content{

    &.two-col{

      columns: 2;
      column-gap: 40px;

      @include media-breakpoint-down(sm){

        columns: 1;

      }

    }

  }

}

.image-wrapper{

  &.small-size{

    height: 300px;

    @include media-breakpoint-down(sm){

      height: 200px;

    }

  }

  &.medium-size{

    height: 600px;

    @include media-breakpoint-down(sm){

      height: 200px;

    }

  }

  &.large-size{

    height: 800px;

    @include media-breakpoint-down(sm){

      height: 200px;

    }

  }

}

// BLOCKQUOTE
.quote-container{

  .quote{

    blockquote{

      font-size: 3rem;
      text-align: center;

    }

    .caption{

      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      margin-top: 40px;

      .js-replace-img{

        width: 80px;
        height: 80px;

        border-radius: 100%;

        margin-right: 10px;

        border: 5px solid #fff;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1), 0 15px 15px 0 rgba(0, 0, 0, 0.1);

      }

      .content{

        p{

          margin-bottom: 0;

        }

      }

    }

  }

  &.gradient{

    background: $gradient;
    padding-top: $margin-base*3;
    padding-bottom: $margin-base*3;

    color: #fff;

  }

}

// TEXT-IMAGE
.text-image{

  .content{

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .text{

      padding-top: 40px;
      padding-bottom: 40px;

      order: 2;

      h2{

        margin-bottom: 20px;

      }

    }

    .js-replace-img{

      order: 1;

      @include media-breakpoint-down(sm){

        min-height: 300px;

      }

    }

  }

  &.right{

    .content{

      .text{

        order: 1;

        @include media-breakpoint-down(sm){

          order: 2;

        }

      }

      .js-replace{

        order: 2;

        @include media-breakpoint-down(sm){

          order: 1;

        }

      }

    }

  }

  &.background{

    background: #fff;
    padding: 40px;

    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.1), 0 24px 24px 0 rgba(0, 0, 0, 0.1);

    @include media-breakpoint-down(sm){

      padding: 20px;

    }

  }

}

// BLOCK ABOUT
.about {

    &__block {
        box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.1), 0 24px 24px 0 rgba(0, 0, 0, 0.1);
        padding: 150px 100px;
        position: relative;

        @include media-breakpoint-down(sm){

          padding: 40px;

        }

        &--title {
            margin-bottom: 60px;

            @include media-breakpoint-down(sm){

              margin-bottom: 20px;

            }
        }

        &--text {
            width: 100%;

            columns: 2;
            column-gap: 40px;

            @include media-breakpoint-down(sm){

              columns: 1;

            }

            & p {
                margin-bottom: 35px;
            }
        }

        &--who:after {
            content: "";
            height: 100%;
            width: 1px;
            background: black;
            display: block;
            position: absolute;
            left: 6px;
            top: 0;
        }

        h2 {
            font-size: 1.6rem;
            color: #000;
            text-transform: uppercase;
            font-weight: bold;
            position: relative;
            margin-bottom: 20px;

            & span {
                font-weight: normal;
            }
        }

        h3{

          font-size: 4.8rem;

          @include media-breakpoint-down(sm){

            font-size: 2rem;

          }

        }
    }
}

// VIDEO
.video-wrapper{

  iframe{

    width: 100%;
    min-height: 450px;

    box-shadow: 0px 0px 40px 0px rgba(0,0,0,0.25);

    @include media-breakpoint-down(sm){

      min-height: 350px;

    }

  }

}

// CARD
.card-wrapper{

  .card{

    border: none;

    @include media-breakpoint-down(sm){

      margin-bottom: 40px;

    }

    >div{

      background: #fff;
      box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.1), 0 24px 24px 0 rgba(0, 0, 0, 0.1);

      .card-body{

        padding: 40px;

        @include media-breakpoint-down(sm){

          padding: 20px;

        }

        h3{

          color: $brand-primary;

        }

      }


    }

  }

}

// PAGINATION
.pagination-wrapper{

  nav{

    ul.pagination{

      margin: 0;
      padding: 0;

      li{

        margin: 0 20px 0 0;
        padding: 0;
        list-style: none;

        color: $brand-primary;

        &:last-child{

          margin: 0;

        }

        a{

          background: #fff;
          box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.1), 0 3px 3px 0 rgba(0, 0, 0, 0.1);
          padding: 10px;

          color: $brand-primary;
          font-size: 1.4rem;

          border: none;

          min-height: 40px;
          min-width: 40px;

          text-align: center;

          line-height: 1.25;

          transition:  all .25s ease;

          &:hover{

            background: $gradient;
            color: #fff;

            transition:  all .25s ease;

          }

        }

        &.active{

          a{

            background: $gradient;
            color: #fff;

          }

        }

      }

    }

  }

}

// ACCORDION
.accordion-wrapper {

  #accordion{

    .card{

      border: none;
      border-bottom: 1px solid #e2e2e2;

      &:last-child{

        border-bottom: none;

      }

      .card-header{

        background: none;
        border: none;
        padding: 0;

        h3{

          margin: 0;

          button{

            padding: 10px;
            padding-left: 0;
            width: 100%;
            text-align: left;

            font-size: 2.4rem;
            color: $brand-secondary;

            transition:  all .25s ease;

            &:hover{

              text-decoration: none;
              color: $brand-primary;

              transition:  all .25s ease;

            }

          }

        }

      }

      .card-body{

        padding: 20px 0;

        font-size: 1.4rem;



      }

    }

  }

}
