.animated {

	transition-duration: .3s;
	transition-timing-function: ease-out;
	transition-delay: 0s;

	&.fade-to-top {

		will-change: transform,opacity, display;
		transition-property: transform, opacity, display;
		transform: translate(0,50px);
		opacity: 0;

		&.active {

			transform: translate(0,0);
			opacity: 1;

		}

		&:nth-child(1n){

			transition-delay: 0s;

		}

		&:nth-child(2n){

			transition-delay: .2s;

		}

		&:nth-child(3n){

			transition-delay: .4s;

		}

		&:nth-child(4n){

			transition-delay: .5s;

		}

	}

	&.fade-to-left {

		will-change: transform,opacity;
		transition-property: transform, opacity;
		transform: translate(50%,0px);
		opacity: 0;

		&.active {

			transform: translate(0,0);
			opacity: 1;

		}

		&:nth-child(1n){

			transition-delay: 0s;

		}

		&:nth-child(2n){

			transition-delay: .2s;

		}

		&:nth-child(3n){

			transition-delay: .4s;

		}

	}

	&.fade-to-right {

		will-change: transform,opacity;
		transition-property: transform, opacity;
		transform: translate(-50%,0);
		opacity: 0;

		&.active {

			transform: translate(0,0);
			opacity: 1;

		}

		&:nth-child(1n){

			transition-delay: 0s;

		}

		&:nth-child(2n){

			transition-delay: .2s;

		}

		&:nth-child(3n){

			transition-delay: .4s;

		}

	}

}
