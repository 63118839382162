.main-footer {

  background: #f8f8f8 url("../images/mdpi/footer.png") no-repeat;
  background-size: contain;
  background-position: top center;

  position: relative;

  position: relative;
  width: 100%;

  margin-top: $margin-base*4;
  padding-top: $margin-base*4;

  .newsletter {

      margin-bottom: $margin-base*4;

      @include media-breakpoint-down(sm){

        margin-bottom: $margin-base*2;

      }

      ul{

        margin: 0;
        padding: 0;

        @include media-breakpoint-down(sm){

          width: 100%;
          justify-content: space-between;

          padding: 0 40px;
          margin-bottom: 40px;

        }

        li{

          margin-right: 20px;
          padding: 0;

          list-style: none;

          a{

            display: block;
            text-align: center;

            line-height: 50px;
            font-size: 2rem;

            color: #000;

            border: 2px solid black;

            width: 50px;
            height: 50px;

            border-radius: 50%;

            transition:  all .75s ease;

            &:hover{

              background: $brand-primary;
              border-color: $brand-primary;
              color: #fff;

              transition:  all .75s ease;

            }

          }

        }

      }

      &__inscription {

          & form {

            display: flex;
            align-content: space-between;

              .form-text {
                  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.1), 0 24px 24px 0 rgba(0, 0, 0, 0.1);
                  border-radius: 50px;
                  border: none;
                  height: 40px;
                  width: 100%;
                  padding-left: 30px;
                  font-size: 1.4rem;

                  &::placeholder {
                      color: #ccc;
                  }

                  @include media-breakpoint-down(sm){

                    margin-bottom: 20px;

                  }
              }

              .form-submit {
                  text-transform: uppercase;
                  color: #fff;
                  font-family: 'Comfortaa', sans-serif;
                  font-weight: 700;
                  font-size: 12px;
                  width: 100%;
                  height: 40px;
                  border: none;
                  background-image: linear-gradient(45deg, $brand-secondary 0%, $brand-secondary 55%, $brand-primary 90%);
                  background-position: 100% 0;
                  background-size: 200% 200%;
                  transition: all .75s ease;


                  &:hover {
                      background-position: 0 0;
                      text-decoration: none;
                      color: #000;
                  }
              }

              .check_container{

                align-items: flex-start;
                margin: 10px 0 0 30px;
                font-size: 1.4rem;

                @include media-breakpoint-down(sm){

                  margin: 20px 0 0 0;

                }

                input[type="checkbox"]{

                  border: 2px solid #000;
                  width: 20px;
                  height: 20px;

                }

                a{

                  text-decoration: underline;
                  color: #000;

                }

              }

          }
      }
  }

  .adresse {

    margin: 0;
    padding: 0;


      li {
        margin: 0;
        padding: 0;
        padding-bottom: 25px;
        padding-top: 25px;
        font-size: 14px;

        list-style: none;

          img{

            max-width: 100%;

          }

          &:nth-child(2) {
              line-height: 30px;
              background-image: url("../images/mdpi/adresse.svg");
              background-repeat: no-repeat;
              background-position: center left;
              padding-left: 60px;
          }

          &:nth-child(3) {
              line-height: 30px;
              background-image: url("../images/mdpi/tel.svg");
              background-repeat: no-repeat;
              background-position: left;
              padding-left: 60px;

              a{

                color: #000;

              }
          }

          .js-replace-img {
              width: 100%;
              max-width: 100%;

              img {
                  width: 100%;
                  width: 28%;
              }
          }
      }
  }

  .footer_menu {

    margin: 0;
    padding: 0;

    @include media-breakpoint-down(sm){

      margin-top: 40px;

    }

    li{

      margin: 0;
      padding: 0;

      list-style: none;

      margin-bottom: 15px;
      font-size: 14px;

      a {

          position: relative;
          color: black;

          &:after{

            content: "";
            background: $gradient;

            width: 0%;
            height: 2px;

            position: absolute;
            bottom: -3px;
            left: 0;

            transition:  all .75s ease;

          }

          &:hover{

            &:after{

              width: 100%;
              transition:  all .75s ease;

            }

          }

      }

      &:first-child {

          color: $brand-primary;
          font-size: 16px;
          margin-bottom: 50px

      }

    }

  }



}
