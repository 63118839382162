.slideshow{

	position: relative;
	background: $brand-primary;

	border: 20px solid #fff;
	margin-bottom: 10px;

	@include media-breakpoint-down(sm){

		border: 15px solid #fff;

	}

}

.slider-wrapper{

	height: 70vh;
	width: 100%;
	position: relative;

	@include media-breakpoint-down(sm){

		min-height: 350px;
		max-height: auto;
		width: 100%;

		margin: 0;

	}

}

/* --------------------------------
Slider
Single slide style
-------------------------------- */
.cd-hero {

  position: relative;
  height: 100%;

	.cd-hero-slider {

	  position: relative;

	  height: 100%;
	  margin:0;
	  padding:0;

		>li {

			background-position: center center;
		  background-size: cover;
		  background-repeat: no-repeat;

		  width: 100%;
		  height: 100%;
		  margin:0;
		  padding:0;
		  list-style: none;

			position: absolute;
		  top: 0;
		  left: 0;

			opacity: 0;

			z-index: 90;

			outline: #fff;

			transition: all 1.5s ease;

			div{

				&.cd-full-width {

				  position: absolute;
				  width: 100%;
				  height: 100%;
				  z-index: 1;
				  left: 0;
				  top: 0;

				}

				&.cd-img-container{

					position: relative;

					.js-replace-img{

						background-size: cover;
						height: 100%;

						&:after {
							content: '';
						  background-image: linear-gradient(to bottom, rgba(148, 203, 249, 0.75) 0, rgba(0, 35, 141, 0.75) 100%);

							position: absolute;
							top: 0;
							left: 0;

						  height: 100%;
						  width: 100%;


						}

						img{

							display: none;

						}

					}

					.text-wrapper{

						position: absolute;
						top: 0;
						left: 0;

						height: 100%;

					}

				}

				&.slide-img{

					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
					align-items: center;

					.js-replace-img{

						background-size: cover;
						height: 100%;
						width: 100%;

						img{

							display: none;

						}

					}

					.image-wrapper{

						background-size: contain;
						background-repeat: no-repeat;

						width: 50%;
						height: 90%;


						opacity: 0;
						transform: translateX(-50px);
						animation-delay: 0.5s;

						transition: all 0.75s ease;

						@include media-breakpoint-down(sm){

							height: auto;
							max-height: auto;

							width: 100%;

							transform: translateX(0);

							order: -1;

						}

						img{

							display: none;

						}

					}

				}

				.text-wrapper{

					display: flex;
					flex-direction: column;
					justify-content: center;

					header{

						margin-bottom: 30px;

						transform: translateY(40px);

						transition: all 1s ease;

						@include media-breakpoint-down(xs){

								text-align: center;

						}

						h2{

							font-size: 4rem;
							line-height: 150%;
							color: #fff;

							text-transform: uppercase;
							text-align: center;

							@include media-breakpoint-down(sm){

								font-size: 2rem;

							}

						}

					}

					.content{

						transform: translateY(40px);

						transition: all 1s ease;

						@include media-breakpoint-down(xs){

								text-align: center;

						}

					}

					footer{

						margin-top: 30px;

						transform: translateY(40px);

						transition: all 1s ease;

						ul{

				      position: relative;
							margin: 0;
							padding: 0;
							text-align: left;

							@include media-breakpoint-down(xs){

									text-align: center;

							}

				      li{

				        margin: 0;
				        padding: 0;
				        list-style: none;

				      }

				    }

					}

				}

			}

			&.selected {

				position: relative;
  			//transform: translateX(0);
				opacity: 1;
				z-index: 99;

				outline: #fff;

				transition: all 1.5s ease;

				.slide-img{

					.image-wrapper{

						opacity: 1;
						transform: translateX(0%);

						transition: all 1s ease;

					}

				}

				.text-wrapper{

					header{

						transform: translateY(0);

						transition: all 1s ease;

					}

					.content{

						transform: translateY(0);

						transition: all 1s ease;

					}

					footer{

						opacity: 1;
						transform: translateY(0);

						transition: all 1s ease;

					}

				}

			}

			&.move-left {

				//transform: translateX(-100%);
				opacity: 0;
				outline: #fff;

				transition: all 1.5s ease;

			}

		}

	}

}

/* --------------------------------

Slider navigation

-------------------------------- */
.cd-slider-nav {

	padding: 20px 0 20px 0;

	width: 100%;
  text-align: center;

	@include media-breakpoint-down(xs){

		/*bottom: 115px;*/

	}
}
.cd-slider-nav nav, .cd-slider-nav ul, .cd-slider-nav li, .cd-slider-nav a {
  /*height: 100%;*/
}
.cd-slider-nav nav {
  display: inline-block;
  position: relative;
  height: 100%;
  width: 100%;
}

.cd-slider-nav ul {
  margin:0 auto;
  padding:0;
}

.cd-slider-nav li {
  display: inline-block;
  margin:0 20px 0 0;
  padding:0;
  list-style: none;
  position: relative;
}

.cd-slider-nav a {
	background: #dddddd;
	border-radius: 100%;
  display: block;
  position: relative;
	width: 20px;
  height: 20px;
  text-indent: -15000px;
  transition: all 0.5s ease;
}

.cd-slider-nav li.selected a {
  background-color: #3399cc;
  transition: all 0.5s ease;
}

/* --------------------------------

Slider navigation arrows

-------------------------------- */
.cd-slider-arrows{

	position: absolute;
	top: auto;
	bottom: 40px;

	width: calc( 100% - 40px);
	margin: 0;
	padding: 0;

	transform: translateY(-50%);

	z-index: 9999;

	@include media-breakpoint-down(xs){

	}

}
.cd-slider-arrows li {
  position: absolute;
  bottom: 0;
  left: 20px;

	list-style: none;
	margin: 0;
	padding: 0;

	//transform: translateY(-50%);

}
.cd-slider-arrows li a {
	background: #fff;

	display: block;
	width: 40px;
	height: 40px;

	box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.20);
	border-radius: 100%;

	font-size: 14px;
	text-align: center;
	color: $brand-primary;

	position: absolute;
	top: 50%;
	transform: translate(0,-50%);

	text-indent: -15000px;

	transition: all .4s $easeInOutQuart;

	&:after {

		content: '';
		display: block;
		width: 25%;
		height: 25%;
		box-sizing: border-box;
		border: .2rem solid $brand-black;

		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-25%,-50%) scale(1,1) rotate(45deg);

		border-top: 0;
		border-right: 0;

		transition: all .4s $easeInOutQuart;

	}

	&:hover{

		background: $brand-primary;
		transition: all .4s $easeInOutQuart;

		&:after{

			border: .2rem solid #fff;

			border-top: 0;
			border-right: 0;

		}

	}

}

.cd-slider-arrows li:last-of-type {

	left: auto;
	right: 20px;

	a{

		&:after {

			transform: translate(-75%,-50%) scale(1,1) rotate(225deg);

		}

	}

}
