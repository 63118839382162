//Image Path
$hdpi-image-path				        :"../images/hdpi/";
$mdpi-image-path				        :"../images/mdpi/";
$nodpi-image-path				        :"../images/svg/";
$icon-font-path                 :"../fonts/";
// Grid settings
$main-sm-columns                :12;
$sidebar-sm-columns             :4;


@import url('https://fonts.googleapis.com/css?family=Comfortaa');

//Font Settings
$font-face-text                 :'Comfortaa', cursive;
$font-face-title                :'Comfortaa', cursive;
$font-base                 		  :1.4rem;
$font-text	            	      :1.8rem;
$font-medium        	       	  :2.0rem;
$font-large 		                :3.0rem;
$font-wide		 	                :8.2rem;

// Colors
$gradient                       :linear-gradient(90deg, #94cbf9 0%, #00238d 100%);
$gradientopcity                 :linear-gradient(90deg, rgba(148,203,249,0.8) 0%, rgba(0,35,141,0.8) 100%);
$maker-primary                  :#000000;
$brand-primary                  :#00238d;
$brand-secondary                :#94cbf9;
$brand-black                    :#000000;
$brand-stable                   :#b3b3bd;
$brand-light-grey               :#e6e6e6;
$brand-light                    :#def6fa;
$brand-dark                     :#4d4d4d;
$facebook-color                 :#3b5998;
$twitter-color                  :#4099FF;

// Margins
$margin-base					          :20px;

// Size
$full                           :100%;
$header-height                  :15rem;
$header-height-min              :7.5rem;

$slider-md-height				        :440px;
$slider-sm-height				        :320px;

// Easings
$easeInSine: cubic-bezier(0.47, 0, 0.745, 0.715);
$easeOutSine: cubic-bezier(0.39, 0.575, 0.565, 1);
$easeInOutSine: cubic-bezier(0.39, 0.575, 0.565, 1);

$easeInQuad: cubic-bezier(0.55, 0.085, 0.68, 0.53);
$easeOutQuad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$easeInOutQuad: cubic-bezier(0.25, 0.46, 0.45, 0.94);

$easeInCubic: cubic-bezier(0.55, 0.055, 0.675, 0.19);
$easeOutCubic: cubic-bezier(0.215, 0.61, 0.355, 1);
$easeInOutCubic: cubic-bezier(0.215, 0.61, 0.355, 1);

$easeInQuart: cubic-bezier(0.895, 0.03, 0.685, 0.22);
$easeOutQuart: cubic-bezier(0.165, 0.84, 0.44, 1);
$easeInOutQuart: cubic-bezier(0.165, 0.84, 0.44, 1);

$easeInQuint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
$easeOutQuint: cubic-bezier(0.23, 1, 0.32, 1);
$easeInOutQuint: cubic-bezier(0.23, 1, 0.32, 1);

$easeInExpo: cubic-bezier(0.95, 0.05, 0.795, 0.035);
$easeOutExpo: cubic-bezier(0.19, 1, 0.22, 1);
$easeInOutExpo: cubic-bezier(0.19, 1, 0.22, 1);

$easeInCirc: cubic-bezier(0.6, 0.04, 0.98, 0.335);
$easeOutCirc: cubic-bezier(0.075, 0.82, 0.165, 1);
$easeInOutCirc: cubic-bezier(0.075, 0.82, 0.165, 1);

$easeInBack: cubic-bezier(0.6, -0.28, 0.735, 0.045);
$easeOutBack: cubic-bezier(0.175, 0.885, 0.32, 1.275);
$easeInOutBack: cubic-bezier(0.68, -0.55, 0.265, 1.55);

$easeInOutFast: cubic-bezier(1,0,0,1);

$authenticMotion: cubic-bezier(.4,0,.2,1);


$line-height-base:        1.428571429; // 20/14
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
$line-height-computed:    floor(($font-size-base * $line-height-base)); // ~20px
