h1 {

	font-size: 4rem;

	background: $gradient;
  background-clip: text;
  -webkit-text-fill-color: transparent;

}

h2 {

	font-size: 3rem;
	color: $brand-primary;

}

h3 {

	font-size: 2rem;

}

h1,h2,h3{

	strong{

		position: relative;
		font-weight: normal;

		@include media-breakpoint-down(sm){

			font-weight: bold;

		}

		&:before {

			content: '';
			height: 50%;
			width: calc(100% + 10px);
			background: #9ed0fa;
			background: linear-gradient(to right, #9ed0fa 0%, #008aff 100%);
			filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#9ed0fa', endColorstr='#008aff', GradientType=1);
			display: block;
			position: absolute;
			left: -5px;
			bottom: 0px;
			z-index: -1;

			@include media-breakpoint-down(sm){

				display: none;

			}

		}

	}

}

p {

	line-height: 180%;
	font-size: 1.4rem;

}



a:link {}
a:hover {

	color:$brand-secondary;
	transition: color 0.25s linear;

}
a:visited {}
a:focus {}
a:link,
a:hover,
a:visited,
a:focus{

	text-decoration: none;

}
