img.fittable {

	opacity: 0;
	transition: opacity 0.5s;
	width: auto!important;
	max-width: none!important;

}

img.fittable.loaded {

	opacity: 1;

}
